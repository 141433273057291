import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  refundPaymentsRequests: [],
  selectedRefundPayment: null,
};

const name = 'refundPayments';
const parentSelector = state => state?.[name];

const refundPaymentSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setRefundPayments: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSelectedRefundPayment: (state, action) => {
      return {
        ...state,
        selectedRefundPayment: action.payload.selectedRefundPayment,
      };
    },
  },
});

const getRefundPayments = createSelector(
  parentSelector,
  state => state?.refundPaymentsRequests
);

const getSelectedRefundPayment = createSelector(
  parentSelector,
  state => state?.selectedRefundPayment
);

const actions = {
  ...refundPaymentSlice.actions,
};

const selectors = {
  getRefundPayments,
  getSelectedRefundPayment,
};

export const refundPaymentActions = { actions, selectors };

export default refundPaymentSlice;
