import React, { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { Stats } from 'components/Stats';
import { Plans } from 'components/Plans';
import { Performance } from 'components/Performance';
import { SalesChart } from 'components/SalesChart';
import styles from './dashboard.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_DASHBOARD_DATA } from '_redux/types/dashboard.types';
import { dashboardActions } from '_redux/slices/dashboard.slice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    companyCommission,
    pendingEarlyPayments,
    pendingRefundPayments,
    approvedEarlyPayments,
    approvedRefundPayments,
    topPlans,
    topAdvisors,
  } = useSelector(dashboardActions.selectors.selectDashboardData);
  console.log(useSelector(dashboardActions.selectors.selectDashboardData));
  useEffect(() => {
    dispatch({ type: FETCH_DASHBOARD_DATA });
  }, [dispatch]);

  return (
    <Box className={styles.dashboardContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stats
            commission={companyCommission?.totalFCI}
            pendingEarlyPayments={pendingEarlyPayments?.pendingEarlyPayment}
            pendingRefundPayments={pendingRefundPayments?.pendingRefund}
            approvedEarlyPayments={approvedEarlyPayments?.approveEarlyPayment}
            approvedRefundPayments={approvedRefundPayments?.approveRefund}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SalesChart />
        </Grid>
        <Grid item xs={12} md={6}>
          <Plans plans={topPlans?.topPlans} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Performance
            title={'best'}
            color={'#E1E8F8'}
            topAdvisors={topAdvisors?.topAdvisors}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
