import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  files: [],
  uploadProgress: 0,
  fileData: null,
};

const name = 'uploads';
const parentSelector = state => state?.[name];

const filesSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setFiles: (state, action) => {
      return {
        ...state,
        files: action.payload,
      };
    },
    setUploadProgress: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload,
      };
    },
    setFileData: (state, action) => {
      return {
        ...state,
        fileData: action.payload,
      };
    },
  },
});

const getFiles = createSelector(parentSelector, state => state?.files);
const getFileData = createSelector(parentSelector, state => state?.fileData);
const getUplaodProgress = createSelector(
  parentSelector,
  state => state?.uploadProgress
);

const actions = {
  ...filesSlice.actions,
};

const selectors = {
  getFiles,
  getFileData,
  getUplaodProgress,
};

export const filesActions = { actions, selectors };

export default filesSlice;
