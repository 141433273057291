import apiInterceptor from './Interceptors/apiInterceptor';

export const updateProfileService = async payload => {
  try {
    const response = await apiInterceptor.put(
      `/api/user/updateProfile/${payload.id}`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
