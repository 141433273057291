import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchPendingEarlyPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/pendingEarlyPayment');
};

export const fetchApprovedEarlyPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/approvedEarlyPayment');
};

export const fetchPendingRefundPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/pendingRefundPayment');
};

export const fetchApprovedRefundPaymentsService = async () => {
  return await apiInterceptor.get('/api/dashboard/approvedRefundPayment');
};

export const fetchCompanyCommissionService = async () => {
  return await apiInterceptor.get('/api/dashboard/companyCommission');
};

export const fetchTopPlansService = async () => {
  return await apiInterceptor.get('/api/dashboard/topPlans');
};

export const fetchTopAdvisorsService = async () => {
  return await apiInterceptor.get('/api/dashboard/topAdvisors');
};

export const fetchTotalAdvisorsService = async () => {
  return await apiInterceptor.get('/api/dashboard/totalAvisor');
};

export const fetchProductTypesService = async () => {
  return await apiInterceptor.get('/api/dashboard/productsType');
};

export const fetchAdvisorBaseService = async () => {
  return await apiInterceptor.get('/api/dashboard/advisorBase');
};

export const fetchTotalSalesService = async payload => {
  return await apiInterceptor.post('/api/dashboard/totalSale', payload);
};
