import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { ERRORS_COLUMNS } from 'utils/errorsData';
import { useDispatch, useSelector } from 'react-redux';
import { errorLogsActions } from '_redux/slices/errorLogs.slice';
import { FETCH_ERROR_LOGS } from '_redux/types/errorLogs.types';

const Error = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const errorLogs = useSelector(errorLogsActions.selectors.getErrorLogs);
  const totalErrorLogs = useSelector(
    errorLogsActions.selectors.getTotalErrorLogs
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch({
      type: FETCH_ERROR_LOGS,
      payload: { limit: rowsPerPage, skip: page * rowsPerPage },
    });
  }, [dispatch, page, rowsPerPage]);

  return (
    <GenericTable
      title="Error Logs"
      data={errorLogs}
      columns={ERRORS_COLUMNS}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: totalErrorLogs,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
    />
  );
};

export default Error;
