import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { COMMISSION_COLUMNS } from 'utils/commission';
import { commissionSplitActions } from '_redux/slices/commissionSplit.slice';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_COMMISSION_SPLITS } from '_redux/types/commissionSplit.types';
import { UPDATE_COMMISSION_SPLIT } from '_redux/types/commissionSplit.types';

const Split = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const commissionSplits = useSelector(
    commissionSplitActions.selectors.getCommissionSplits
  );
  const totalCommissionSplits = useSelector(
    commissionSplitActions.selectors.getTotalCommissionSplits
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);

  useEffect(() => {
    dispatch({
      type: FETCH_COMMISSION_SPLITS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
      },
    });
  }, [dispatch, page, rowsPerPage]);

  const handleUpdateRow = async updatedData => {
    try {
      dispatch({
        type: UPDATE_COMMISSION_SPLIT,
        payload: updatedData,
      });
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  return (
    <GenericTable
      title="Commission Split"
      data={commissionSplits}
      columns={COMMISSION_COLUMNS}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: totalCommissionSplits,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      isEdit={true}
      onUpdateRows={handleUpdateRow}
    />
  );
};

export default Split;
