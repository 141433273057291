import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_EARLY_PAYMENTS } from '_redux/types/earlyPayments.types';
import { earlyPaymentActions } from '_redux/slices/earlyPayment.slice';
import { getEarlyPaymentsService } from '_redux/services/earlyPayments.service';
import { GET_EARLY_PAYMENT_BY_ID } from '_redux/types/earlyPayments.types';
import { getEarlyPaymentByIdService } from '_redux/services/earlyPayments.service';
import { EARLY_PAYMENT_ADMIN_VERIFICATION } from '_redux/types/earlyPayments.types';
import { adminVerificationService } from '_redux/services/earlyPayments.service';
import toast from 'react-hot-toast';

export function* getEarlyPayments(action) {
  try {
    const response = yield call(getEarlyPaymentsService, action.payload);

    if (response?.status === 200) {
      yield put(
        earlyPaymentActions.actions.setEarlyPayments({
          earlyPaymentsRequests: response.data,
        })
      );
    }
  } catch (err) {
    // const errorMessage = err?.response?.data?.message;
    console.log('error: ', err);
  }
}

export function* getEarlyPaymentById(action) {
  try {
    const response = yield call(getEarlyPaymentByIdService, action.payload);

    if (response?.status === 200) {
      yield put(
        earlyPaymentActions.actions.setSelectedEarlyPayment({
          selectedEarlyPayment: response.data,
        })
      );
    }
  } catch (err) {
    console.log('error: ', err);
  }
}

export function* adminVerification(action) {
  try {
    const response = yield call(adminVerificationService, action.payload);

    if (response?.status === 200) {
      toast.success('Status Updated');
    } else {
      toast.error('Updation Failed');
    }
  } catch (err) {
    console.log('error: ', err);
    toast.error('Updation Failed');
  }
}

export function* watchEarlyPaymentsAsync() {
  yield takeEvery(GET_EARLY_PAYMENTS, getEarlyPayments);
  yield takeEvery(GET_EARLY_PAYMENT_BY_ID, getEarlyPaymentById);
  yield takeEvery(EARLY_PAYMENT_ADMIN_VERIFICATION, adminVerification);
}
