import { formatDate } from 'utils';

export const SALES_DATA = [
  {
    id: 1,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 2,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 3,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 4,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 5,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 6,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
  {
    id: 7,
    transactionID: '5648256326',
    clientName: 'Willium',
    premium: '£170,00',
    frequency: 'Monthly',
    grossFCI: '£70',
    FCIRecognition: '£70',
    percentagePayable: '65',
    payable: '£34',
    cashType: 'Policy',
    cashMacthID: '250521',
    paymentDate: '25-05-24',
    provider: 'Provider X',
    planType: 'Pension',
    plzNumber: 'ZC324536',
    startDate: '25-05-24',
    endDate: 'N/A',
  },
];

export const SALES_COLUMNS = [
  { headerName: 'Transaction ID', field: 'transactionID' },
  { headerName: 'Client Name', field: 'clientName' },
  {
    headerName: 'Premium',
    field: 'premium',
    renderCell: row => `£${row?.premium?.toFixed(2)}`,
  },
  { headerName: 'Frequency', field: 'frequency' },
  {
    headerName: 'Gross FCI',
    field: 'grossFCI',
    renderCell: row => `£${row?.grossFCI?.toFixed(2)}`,
  },
  {
    headerName: 'FCI Recognition',
    field: 'FCIRecognition',
    renderCell: row => `£${row?.FCIRecognition?.toFixed(2)}`,
  },
  {
    headerName: 'Percentage Payable',
    field: 'percentagePayable',
    renderCell: row => `${row?.percentagePayable?.toFixed(2)}%`,
    editAble: true,
  },
  { headerName: 'Payable', field: 'payable' },
  { headerName: 'Cash Type', field: 'cashType' },
  { headerName: 'Cash Match ID', field: 'cashMatchId' },
  {
    headerName: 'Payement Date',
    field: 'paymentDate',
    renderCell: params => {
      return formatDate(params?.paymentDate);
    },
  },
  { headerName: 'Provider', field: 'provider' },
  { headerName: 'Plan Type', field: 'planType' },
  { headerName: 'PLZ Number', field: 'plzNumber' },
  { headerName: 'Split type', field: 'splitType' },
  { headerName: 'Advisor/partner Name', field: 'advisorName' },
  { headerName: 'Split percentage', field: 'splitPercentage' },
  {
    headerName: 'Start Date',
    field: 'startDate',
    renderCell: params => {
      return formatDate(params?.startDate);
    },
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    renderCell: params => {
      return formatDate(params?.endDate);
    },
  },
];
