import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchErrorLogsService = async ({ limit, skip }) => {
  try {
    const response = await apiInterceptor.get(
      `/api/errorLogs?limit=${limit}&skip=${skip}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch error logs failed');
  }
};

export const updateValidationService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/errorLogs?limit=10&skip=0',
      {
        data: updatedData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
