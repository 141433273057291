import React, { useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import SettingsAnimations from 'components/common/Animations/SettingsAnimations';
import styles from './password.module.scss';
import { Title } from 'components/common/Title';
import { Tabs } from 'components/common/Tabs';
import { authActions } from '_redux/slices/auth.slice';
import { RESET_PASSWORD } from '_redux/types/auth.types';

const validationSchema = Yup.object({
  currentPassword: Yup.string().required('Current password is required'),
  newPassword: Yup.string()
    .min(6, 'New password must be at least 6 characters')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm your new password'),
});

const Password = ({ tab, tabs, handleTabChange }) => {
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const dispatch = useDispatch();
  const { user } = useSelector(authActions.selectors.getUser);

  const handleClickShowPassword = field => {
    setShowPassword(prevState => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSubmit = (values, { resetForm }) => {
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        userId: user?.id,
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
      onSuccess: () => {
        resetForm();
      },
    });
  };

  return (
    <Box className={styles.passwordContainer}>
      <SettingsAnimations type="fadeInUp" duration={0.6}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Title title="Change Password" />
        </Box>
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      </SettingsAnimations>
      <SettingsAnimations type="fadeInUp" duration={0.8}>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            resetForm,
          }) => (
            <Form className={styles.formContainer}>
              <Grid container spacing={3} className={styles.formFields}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="currentPassword"
                    label="Current Password"
                    variant="outlined"
                    type={showPassword.currentPassword ? 'text' : 'password'}
                    value={values.currentPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.currentPassword && Boolean(errors.currentPassword)
                    }
                    helperText={
                      touched.currentPassword && errors.currentPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickShowPassword('currentPassword')
                            }
                            edge="end"
                          >
                            {showPassword.currentPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="newPassword"
                    label="New Password"
                    variant="outlined"
                    type={showPassword.newPassword ? 'text' : 'password'}
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickShowPassword('newPassword')
                            }
                            edge="end"
                          >
                            {showPassword.newPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    variant="outlined"
                    type={showPassword.confirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              handleClickShowPassword('confirmPassword')
                            }
                            edge="end"
                          >
                            {showPassword.confirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={styles.textField}
                  />
                </Grid>
              </Grid>

              <Box className={styles.actionButtons}>
                <SettingsAnimations type="fadeInLeft" delay={0.8}>
                  <Button
                    variant="outlined"
                    className={styles.cancelButton}
                    onClick={() => resetForm()}
                  >
                    Cancel
                  </Button>
                </SettingsAnimations>
                <SettingsAnimations type="fadeInRight" delay={0.9}>
                  <Button
                    variant="contained"
                    className={styles.saveButton}
                    type="submit"
                  >
                    {'Save Changes'}
                  </Button>
                </SettingsAnimations>
              </Box>
            </Form>
          )}
        </Formik>
      </SettingsAnimations>
    </Box>
  );
};

export default Password;
