import axios from 'axios';
import { store } from '_redux/store';

const API_URL = process.env.REACT_APP_API_URL;

const apiInterceptor = axios.create({
  baseURL: API_URL,
});

apiInterceptor.interceptors.request.use(
  function (config) {
    const state = store.getState();
    const token = state?.auth?.user?.user?.accessToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiInterceptor;
