import {
  ToValidate,
  UnpaidIcon,
  LateIcon,
  CancelledIcon,
  PaidIcon,
} from 'assets/svgs';
import { format } from 'date-fns';

export const STATS_DATA = [
  {
    icon: <ToValidate />,
    label: 'Overall Commission',
    count: 50,
    backgroundColor: '#EAF2FF',
  },
  {
    icon: <UnpaidIcon />,
    label: 'Early Payment Pending',
    count: 10,
    backgroundColor: '#FFF4E0',
  },
  {
    icon: <LateIcon />,
    label: 'Refunds Pending',
    count: 5,
    backgroundColor: '#E0FFF4',
  },
  {
    icon: <CancelledIcon />,
    label: 'Early payment approved',
    count: 15,
    backgroundColor: '#F4E0FF',
  },
  {
    icon: <PaidIcon />,
    label: 'Refund Approved',
    count: 14,
    backgroundColor: '#FFFCE0',
  },
];

export const CHART_OPTIONS = {
  chart: {
    toolbar: { show: false },
    zoom: { enabled: false },
    foreColor: '#718EBF',
    fontFamily: 'Roboto, sans-serif',
  },
  stroke: {
    width: 3,
    curve: 'smooth',
    colors: ['#FECE00'],
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      type: 'vertical',
      shade: 'light',
      gradientToColors: undefined,
      inverseColors: false,
      opacityFrom: 0.25,
      opacityTo: 0,
      stops: [0, 100],
      colorStops: [
        {
          offset: 0,
          color: 'rgba(254, 206, 0, 0.30)',
          opacity: 1,
        },
        {
          offset: 100,
          color: 'rgba(254, 206, 0, 0)',
          opacity: 0,
        },
      ],
    },
  },
  grid: {
    strokeDashArray: 5,
    borderColor: '#DFE5EE',
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  xaxis: {
    categories: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
    axisBorder: { show: false },
    axisTicks: { show: false },
  },
  yaxis: {
    min: 0,
    max: 800,
    tickAmount: 4,
    labels: {
      formatter: value => value.toFixed(0),
    },
    tooltip: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    theme: 'light',
  },
  markers: {
    size: 0,
    colors: ['#FECE00'],
    strokeColors: '#ffffff',
    strokeWidth: 2,
  },
  legend: {
    show: false,
  },
};

export const CHART_SERIES = [
  {
    name: 'Sales',
    data: [120, 300, 600, 800, 400, 500, 700],
  },
];

export const PLANS_DATA = [
  {
    name: 'Pension',
    popularity: 45,
    sales: '45%',
  },
  {
    name: 'Insurance B',
    popularity: 29,
    sales: '29%',
  },
  {
    name: 'Pension',
    popularity: 18,
    sales: '18%',
  },
  {
    name: 'Pension',
    popularity: 25,
    sales: '25%',
  },
];

export const COLORS = [
  {
    color: '#FF6B6B',
    backgroundColor: '#FFF6F6',
    progressBarBackground: 'rgba(255, 107, 107, 0.2)',
  },
  {
    color: '#6BCB77',
    backgroundColor: '#F4FDF4',
    progressBarBackground: 'rgba(107, 203, 119, 0.2)',
  },
  {
    color: '#8C67F5',
    backgroundColor: '#F6F0FF',
    progressBarBackground: 'rgba(140, 103, 245, 0.2)',
  },
  {
    color: '#F5A623',
    backgroundColor: '#FFF8E8',
    progressBarBackground: 'rgba(245, 166, 35, 0.2)',
  },
];

export const PERFORMANCE_DATA = [
  {
    name: 'John Watt',
    email: 'hognwatt23@gmail.com',
    plan: 'Insurance',
    avatar: '/path-to-avatar1.jpg',
  },
  {
    name: 'Edward Smith',
    email: 'edwardsmith345@gmail.com',
    plan: 'Pension',
    avatar: '/path-to-avatar2.jpg',
  },
  {
    name: 'Henry Kate',
    email: 'hognwatt23@gmail.com',
    plan: 'Insurance',
    avatar: '/path-to-avatar3.jpg',
  },
];

export const formatDate = dateString => {
  if (!dateString) {
    return 'N/A'; // Handle null, undefined, or empty string
  }

  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date'; // Handle invalid date strings
  }

  return format(date, 'MMMM d, yyyy'); // Format valid dates (e.g., "November 13, 2024")
};

export const getRandomColor = () => {
  const colors = [
    '#FF6B6B',
    '#6BCB77',
    '#8C67F5',
    '#F5A623',
    '#FF4081',
    '#00BCD4',
    '#FFC107',
    '#795548',
  ];

  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
