import React from 'react';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { REQUEST_REFUND_PAYMENT } from '_redux/types/payout.types';
import styles from './UserRefundRequest.module.scss';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';

const validationSchema = Yup.object({
  employeeId: Yup.string().required('Employee ID is required'),
  employeeName: Yup.string().required('Employee Name is required'),
  totalCommission: Yup.number()
    .required('Total commission is required')
    .positive('Must be a positive number'),
  requestPaymentAmount: Yup.number()
    .required('Request refund amount is required')
    .positive('Must be a positive number'),
  earlyPaymentReason: Yup.string()
    .required('Reason is required')
    .min(5, 'Reason must be at least 5 characters long'),
  requestDate: Yup.date().required('Request date is required'),
});

const UserRefundRequest = ({ onClose, open }) => {
  const dispatch = useDispatch();

  const initialValues = {
    employeeId: '',
    employeeName: '',
    totalCommission: '',
    requestPaymentAmount: '',
    earlyPaymentReason: '',
    requestDate: new Date().toISOString().substring(0, 10),
  };

  const handleSubmit = (values, { resetForm }) => {
    const requestData = {
      ...values,
    };

    dispatch({
      type: REQUEST_REFUND_PAYMENT,
      payload: requestData,
      onSuccess: () => {
        onClose();
        resetForm();
      },
    });
  };

  return (
    <ModalContainer open={open}>
      <Box p={3}>
        <Typography variant="h6" mb={3}>
          Refund Request
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, errors, touched }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="employeeId"
                    label="Employee ID"
                    variant="outlined"
                    value={values.employeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.employeeId && Boolean(errors.employeeId)}
                    helperText={touched.employeeId && errors.employeeId}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="employeeName"
                    label="Employee Name"
                    variant="outlined"
                    value={values.employeeName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.employeeName && Boolean(errors.employeeName)}
                    helperText={touched.employeeName && errors.employeeName}
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="totalCommission"
                    label="Total Commission Earned"
                    variant="outlined"
                    value={values.totalCommission}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.totalCommission && Boolean(errors.totalCommission)
                    }
                    helperText={
                      touched.totalCommission && errors.totalCommission
                    }
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="requestPaymentAmount"
                    label="Requested Refund Amount"
                    variant="outlined"
                    value={values.requestPaymentAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.requestPaymentAmount &&
                      Boolean(errors.requestPaymentAmount)
                    }
                    helperText={
                      touched.requestPaymentAmount &&
                      errors.requestPaymentAmount
                    }
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="earlyPaymentReason"
                    label="Reason for Refund"
                    variant="outlined"
                    value={values.earlyPaymentReason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.earlyPaymentReason &&
                      Boolean(errors.earlyPaymentReason)
                    }
                    helperText={
                      touched.earlyPaymentReason && errors.earlyPaymentReason
                    }
                    className={styles.textField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="requestDate"
                    label="Request Date"
                    type="date"
                    variant="outlined"
                    value={values.requestDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.requestDate && Boolean(errors.requestDate)}
                    helperText={touched.requestDate && errors.requestDate}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

              <Box mt={3} display="flex" justifyContent="end" gap={1}>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Submit Request
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalContainer>
  );
};

export default UserRefundRequest;
