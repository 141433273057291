import { call, put, takeEvery } from 'redux-saga/effects';
import { UPDATE_PROFILE } from '_redux/types/profile.types';
import { updateProfileService } from '_redux/services/profile.service';
import { profileActions } from '_redux/slices/profile.slice';
import toast from 'react-hot-toast';
import { authActions } from '_redux/slices/auth.slice';

export function* updateProfile(action) {
  try {
    yield put(profileActions.actions.setLoading(true));
    const response = yield call(updateProfileService, action.payload);
    if (response?.status === 200) {
      yield put(profileActions.actions.setProfile(response.data));
      yield put(authActions.actions.setProfileSlice({ user: response.data }));
      toast.success('Profile updated!');
    } else {
      yield put(profileActions.actions.setError('Failed to update profile.'));
    }
  } catch (error) {
    yield put(profileActions.actions.setError(error.message));
  } finally {
    yield put(profileActions.actions.setLoading(false));
  }
}

export function* watchProfileAsync() {
  yield takeEvery(UPDATE_PROFILE, updateProfile);
}
