import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    address: '',
  },
  isLoading: false,
  error: null,
};

const name = 'profile';
const parentSelector = state => state?.[name];

const profileSlice = createSlice({
  name: name,
  initialState: initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { setProfile, setLoading, setError } = profileSlice.actions;

const getProfile = createSelector(parentSelector, state => state?.profile);

const actions = {
  ...profileSlice.actions,
};

const selectors = {
  getProfile,
};

export const profileActions = { actions, selectors };

export default profileSlice;
