import { Button } from '@mui/material';
import { requestchip } from './styles';
import { Visibility } from '@mui/icons-material';
import { formatDate } from 'utils';

export const paymentColumns = handleOpen => [
  { headerName: 'Request ID', field: 'id' },
  { headerName: 'Advisors Name', field: 'employeeName' },
  {
    headerName: 'Request Payment Amount',
    field: 'requestPaymentAmount',
    renderCell: row => `£ ${row?.requestPaymentAmount}`,
  },
  {
    headerName: 'Request Date',
    field: 'requestDate',
    renderCell: row => `${formatDate(row?.requestDate)}`,
  },
  {
    headerName: 'Approval Status',
    field: 'status',
    renderCell: row => (
      <Button variant="outlined" size="small" sx={requestchip(row)}>
        {row.status}
      </Button>
    ),
  },
  {
    headerName: 'View',
    renderCell: row => (
      <Button
        variant="text"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => handleOpen(row)}
      >
        <Visibility sx={{ color: '#687182' }} />
      </Button>
    ),
  },
];

export const PAYMENT_HISTORY_COLUMNS = [
  { headerName: 'Request ID', field: 'requestID' },
  { headerName: 'Advisors Name', field: 'employeeName' },
  { headerName: 'Request Payment Amount', field: 'requestPaymentAmount' },
  { headerName: 'Request Date', field: 'requestDate' },
  {
    headerName: 'Approval Status',
    field: 'status',
    renderCell: row => (
      <Button variant="outlined" size="small" sx={requestchip(row)}>
        {row.status}
      </Button>
    ),
  },
  {
    headerName: 'View',
    renderCell: row => (
      <Button
        variant="text"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Visibility sx={{ color: '#687182' }} />
      </Button>
    ),
  },
];

export const PAYMENT_DATA = [
  {
    id: 1,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Approved',
  },
  {
    id: 2,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Pending',
  },
  {
    id: 3,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Rejected',
  },
  {
    id: 4,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Approved',
  },
  {
    id: 5,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Pending',
  },
  {
    id: 6,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Rejected',
  },
  {
    id: 7,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Approved',
  },
  {
    id: 8,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Pending',
  },
  {
    id: 9,
    requestID: '5684236526',
    employeeName: 'William',
    requestPaymentAmount: '£1000',
    requestDate: '25-05-24',
    status: 'Rejected',
  },
];

export const EARLY_PAY_REQUEST_COLUMNS = [
  { headerName: 'Manager Name', field: 'managerName' },
  {
    headerName: 'Date',
    field: 'requestDate',
    renderCell: row => `${formatDate(row?.requestDate)}`,
  },
  {
    headerName: 'Approval Status',
    field: 'status',
    renderCell: row => (
      <Button variant="outlined" size="small" sx={requestchip(row)}>
        {row.status}
      </Button>
    ),
  },
];

export const EARLY_PAY_REQUEST_DATA = [
  {
    id: 1,
    managerName: 'William',
    date: '25-05-24',
    status: 'Approved',
  },
  {
    id: 2,
    managerName: 'William',
    date: '25-05-24',
    status: 'Approved',
  },
  {
    id: 3,
    managerName: 'William',
    date: '25-05-24',
    status: 'Approved',
  },
  {
    id: 4,
    managerName: 'William',
    date: '25-05-24',
    status: 'Approved',
  },
  {
    id: 5,
    managerName: 'William',
    date: '25-05-24',
    status: 'Approved',
  },
];
