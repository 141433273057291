import apiInterceptor from './Interceptors/apiInterceptor';

export const fetchCommissionSplitsService = async ({ limit, skip }) => {
  try {
    const response = await apiInterceptor.get(
      `/api/commissionSplits?limit=${limit}&skip=${skip}`
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Fetch error logs failed');
  }
};

export const updateCommissionSplitService = async updatedData => {
  try {
    const response = await apiInterceptor.put(
      '/api/commissionSplits/updateCommission?limit=10&skip=0',
      {
        data: updatedData,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
