import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { dataColumns } from 'utils/uploadData';
import { useDispatch, useSelector } from 'react-redux';
import { GET_FILES } from '_redux/types/files.types';
import { filesActions } from '_redux/slices/files.slice';
import { useNavigate } from 'react-router-dom';

const UploadData = ({ onUploadClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch({ type: GET_FILES });
  }, [dispatch]);

  const files = useSelector(filesActions.selectors.getFiles);

  return (
    <GenericTable
      title="Data Upload"
      data={files}
      columns={dataColumns(navigate)}
      pageConfig={{ page, setPage, rowsPerPage, setRowsPerPage }}
      onUploadClick={onUploadClick}
      isMain={true}
      isUpload={true}
    />
  );
};

export default UploadData;
