import React, { useState } from 'react';
import {
  Card,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
} from '@mui/material';
import { CloudUpload, Edit, Search } from '@mui/icons-material';
import styles from './genericTable.module.scss';
import Animation from 'utils/Animation';
import { card } from 'utils/styles';
import { uploadButton } from 'utils/styles';
import { icon } from 'utils/styles';
import { Title } from 'components/common/Title';
import LeftToRight from 'utils/LeftToRight';
import { Tabs } from 'components/common/Tabs';
import ColsSettings from './ColsSettings/ColsSettings';
import { ColFilterIcon } from 'assets/svgs';
import { FilterIcon } from 'assets/svgs';

const GenericTable = ({
  title,
  data,
  columns,
  pageConfig,
  onUploadClick,
  isMain = false,
  isTabs = false,
  handleTabChange,
  tab,
  tabs = [],
  isUpload = false,
  isSearch = true,
  isEdit = false,
  isRefundRequest = false,
  isPayRequest = false,
  onEarlyPayRequest,
  onRefundRequest,
  onUpdateRows,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCols, setSelectedCols] = useState(
    columns.map(col => col.field)
  );
  const { page, setPage, rowsPerPage, setRowsPerPage } = pageConfig;

  const [updatedRows, setUpdatedRows] = useState({});
  const handleSaveClick = () => {
    const rowsToUpdate = Object.keys(updatedRows).map(rowId => ({
      id: rowId,
      updatedFields: updatedRows[rowId],
    }));

    if (onUpdateRows && rowsToUpdate.length > 0) {
      onUpdateRows(rowsToUpdate);
      setUpdatedRows({});
    }
  };

  const handleFieldChange = (rowId, field, value) => {
    setUpdatedRows(prev => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [field]: value,
      },
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenColsSettings = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseColsSettings = () => {
    setAnchorEl(null);
  };

  const handleToggleCol = colField => {
    setSelectedCols(prev =>
      prev.includes(colField)
        ? prev.filter(field => field !== colField)
        : [...prev, colField]
    );
  };

  const filteredColumns = columns.filter(col =>
    selectedCols.includes(col.field)
  );

  return (
    <Card sx={card}>
      {isSearch ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box className={styles.buttonsBox}>
            <Title title={title} />
            <Box className={styles.buttons}>
              {isUpload ? (
                <Animation>
                  <Button
                    variant="contained"
                    startIcon={<CloudUpload />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={onUploadClick}
                  >
                    Upload
                  </Button>
                </Animation>
              ) : null}
              {isRefundRequest ? (
                <Animation>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#FFCE00',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#002244',
                        color: 'white',
                      },
                    }}
                    onClick={() => onRefundRequest()}
                  >
                    Refund Request
                  </Button>
                </Animation>
              ) : null}
              {isPayRequest ? (
                <Animation>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#FFCE00',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#002244',
                        color: 'white',
                      },
                    }}
                    onClick={() => onEarlyPayRequest()}
                  >
                    Early Payment Request
                  </Button>
                </Animation>
              ) : null}
              {isEdit && Object.keys(updatedRows || {})?.length > 0 ? (
                <Animation>
                  <Button
                    variant="contained"
                    startIcon={<Edit />}
                    sx={{
                      textTransform: 'none',
                      borderRadius: '8px',
                      backgroundColor: '#003366',
                      '&:hover': {
                        backgroundColor: '#002244',
                      },
                    }}
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </Animation>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
      {isTabs && tabs.length > 0 ? (
        <Tabs tabs={tabs} value={tab} onChange={handleTabChange} />
      ) : null}
      <Box sx={uploadButton}>
        {isSearch ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
            p={1}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search..."
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E0E0E0',
                  },
                },
              }}
              sx={{ width: '100%' }}
            />
            <IconButton sx={icon}>
              <FilterIcon />
            </IconButton>
            <IconButton sx={icon} onClick={handleOpenColsSettings}>
              <ColFilterIcon />
            </IconButton>
          </Box>
        ) : null}
        <ColsSettings
          columns={columns}
          selectedCols={selectedCols}
          onToggleCol={handleToggleCol}
          anchorEl={anchorEl}
          handleClose={handleCloseColsSettings}
        />
        <TableContainer className={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {filteredColumns.map((column, index) => (
                  <TableCell
                    key={index}
                    className={styles.tableHeadCell}
                    padding={column.padding || 'normal'}
                  >
                    <Animation delay={index * 0.1}>
                      {column.headerName}
                    </Animation>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      backgroundColor: isMain
                        ? row.status === 'Pending'
                          ? '#F5D9D9'
                          : '#ffffff'
                        : index % 2 == 0
                          ? '#ffffff'
                          : '#F9FAFC',
                    }}
                  >
                    {filteredColumns.map((column, colIndex) => (
                      <TableCell
                        key={colIndex}
                        className={styles.tableBodyCell}
                      >
                        <LeftToRight index={index}>
                          {isEdit && column.editAble ? (
                            <TextField
                              fullWidth
                              variant="standard"
                              InputProps={{
                                disableUnderline: true,
                                sx: {
                                  // padding: '5px 0',
                                  backgroundColor: 'transparent',
                                  border: 'none',
                                  fontSize: 'inherit',
                                  color: 'inherit',
                                },
                              }}
                              value={
                                updatedRows[row.id]?.[column.field] !==
                                undefined
                                  ? `${updatedRows[row.id][column.field]}`
                                  : Math.ceil(row[column.field]) !==
                                        undefined && row[column.field] !== null
                                    ? `${Math.ceil(row[column.field])}`
                                    : ''
                              }
                              onChange={e => {
                                const inputValue = e.target.value
                                  .replace('%', '')
                                  .trim();
                                if (!isNaN(inputValue) || inputValue === '') {
                                  handleFieldChange(
                                    row.id,
                                    column.field,
                                    parseInt(inputValue) || ''
                                  );
                                }
                              }}
                            />
                          ) : column.renderCell ? (
                            column.renderCell(row)
                          ) : (
                            row[column.field]
                          )}
                        </LeftToRight>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter className={styles.tableFooterRow}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              colSpan={columns.length}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </Card>
  );
};

export default GenericTable;
