import apiInterceptor from './Interceptors/apiInterceptor';

export const getFilesService = async payload => {
  try {
    const response = await apiInterceptor.get(`/api/upload`);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const uploadFileService = async (formData, onUploadProgress) => {
  try {
    const response = await apiInterceptor.post(
      '/api/upload/fileUpload',
      formData,
      {
        onUploadProgress: progressEvent => {
          if (onUploadProgress) {
            const { loaded, total } = progressEvent;
            // Ensure progress does not exceed 100%
            const progress = Math.min((loaded / total) * 100, 100);
            onUploadProgress(progress);
          }
        },
      }
    );
    return response;
  } catch (error) {
    // Capture status code and error message for better debugging
    const errorMessage = error.response?.data?.message || 'Server Error';
    const statusCode = error.response?.status;
    throw new Error(
      `Error: ${errorMessage} (Status Code: ${statusCode || 'N/A'})`
    );
  }
};

export const fetchFileDataService = async id => {
  try {
    const response = await apiInterceptor.get(`/api/upload/${id}`);
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};

export const updateFileService = async updatedData => {
  try {
    const response = await apiInterceptor.put('/api/saleData?limit=10&skip=0', {
      data: updatedData,
    });
    return response;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Server Error');
  }
};
