import { all } from 'redux-saga/effects';
import { watchAuthAsync } from './auth.saga';
import { watchEarlyPaymentsAsync } from './earlyPayments.saga';
import { watchRefundPaymentsAsync } from './refundPayments.saga';
import { watchFilesAsync } from './files.saga';
import { watchProfileAsync } from './profile.saga';
import { watchPayoutsAsync } from './payout.saga';
import { watchErrorLogsAsync } from './errorLogs.saga';
import { watchCommissionSplitsAsync } from './commissionSplit.saga';
import { watchDashboardAsync } from './dashboard.saga';

export function* rootSaga() {
  yield all([
    watchAuthAsync(),
    watchEarlyPaymentsAsync(),
    watchRefundPaymentsAsync(),
    watchFilesAsync(),
    watchProfileAsync(),
    watchPayoutsAsync(),
    watchErrorLogsAsync(),
    watchCommissionSplitsAsync(),
    watchDashboardAsync(),
  ]);
}
