import { call, put, all, takeEvery } from 'redux-saga/effects';

import {
  fetchPendingEarlyPaymentsService,
  fetchApprovedEarlyPaymentsService,
  fetchPendingRefundPaymentsService,
  fetchApprovedRefundPaymentsService,
  fetchCompanyCommissionService,
  fetchTopPlansService,
  fetchTopAdvisorsService,
  fetchTotalAdvisorsService,
  fetchProductTypesService,
  fetchAdvisorBaseService,
  fetchTotalSalesService,
} from '_redux/services/dashboard.service';

import {
  setPendingEarlyPayments,
  setApprovedEarlyPayments,
  setPendingRefundPayments,
  setApprovedRefundPayments,
  setCompanyCommission,
  setTopPlans,
  setTopAdvisors,
  setTotalAdvisors,
  setProductTypes,
  setAdvisorBase,
  setTotalSales,
  setError,
} from '_redux/slices/dashboard.slice';
import { FETCH_DASHBOARD_DATA } from '_redux/types/dashboard.types';

function* fetchDashboardData() {
  try {
    const [
      pendingEarlyPayments,
      approvedEarlyPayments,
      pendingRefundPayments,
      approvedRefundPayments,
      companyCommission,
      topPlans,
      topAdvisors,
      totalAdvisors,
      productTypes,
      advisorBase,
      totalSales,
    ] = yield all([
      call(fetchPendingEarlyPaymentsService),
      call(fetchApprovedEarlyPaymentsService),
      call(fetchPendingRefundPaymentsService),
      call(fetchApprovedRefundPaymentsService),
      call(fetchCompanyCommissionService),
      call(fetchTopPlansService),
      call(fetchTopAdvisorsService),
      call(fetchTotalAdvisorsService),
      call(fetchProductTypesService),
      call(fetchAdvisorBaseService),
      call(fetchTotalSalesService, { period: 'week' }),
    ]);

    yield put(setPendingEarlyPayments(pendingEarlyPayments.data));
    yield put(setApprovedEarlyPayments(approvedEarlyPayments.data));
    yield put(setPendingRefundPayments(pendingRefundPayments.data));
    yield put(setApprovedRefundPayments(approvedRefundPayments.data));
    yield put(setCompanyCommission(companyCommission.data));
    yield put(setTopPlans(topPlans.data));
    yield put(setTopAdvisors(topAdvisors.data));
    yield put(setTotalAdvisors(totalAdvisors.data));
    yield put(setProductTypes(productTypes.data));
    yield put(setAdvisorBase(advisorBase.data));
    yield put(setTotalSales(totalSales.data));
  } catch (error) {
    yield put(setError(error.message || 'Error fetching dashboard data'));
  }
}

export function* watchDashboardAsync() {
  yield takeEvery(FETCH_DASHBOARD_DATA, fetchDashboardData);
}
