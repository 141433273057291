export const COMMISSION_DATA = [
  {
    id: 1,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 2,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 3,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 4,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 5,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 6,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 7,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
  {
    id: 8,
    transactionID: '5648256326',
    advisorID: 'AD0032',
    advisorName: 'John Whick',
    splitPercentage: '20%',
    splitCommissionAmount: '£8.67',
    introducerID: 'IN 001',
    introducerName: 'Henry John',
    net: 'Net',
    gross: 'Gross',
  },
];

export const COMMISSION_COLUMNS = [
  { headerName: 'Transaction ID', field: 'transactionID' },
  { headerName: 'Advisor ID', field: 'advisorId' },
  { headerName: 'Advisor Name', field: 'advisorName' },
  {
    headerName: 'Split Percentage',
    field: 'splitPercentage',
    editAble: true,
    renderCell: row => `${row?.splitPercentage?.toFixed(2)}%`,
  },
  {
    headerName: 'Split Commission Amount',
    field: 'splitAmount',
    renderCell: row => `£${row?.splitAmount?.toFixed(2)}`,
  },
  { headerName: 'Split Partner ID', field: 'splitPartnerId' },
  { headerName: 'Split Partner Name', field: 'splitPartnerName' },
  { headerName: 'Net', field: 'net' },
  { headerName: 'Gross', field: 'gross' },
];

export const ADVISOR_DATA = [
  {
    id: 1,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Advisor',
    splitPartnerID: '-',
    splitPartnerName: '-',
    splitPartnerPercentage: '-',
    firstAdvisorID: 'AD0032',
    firstAdvisorName: 'William Smith',
    firstAdvisorSplitPercentage: '20%',
  },
  {
    id: 2,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Advisor',
    splitPartnerID: '-',
    splitPartnerName: '-',
    splitPartnerPercentage: '-',
    firstAdvisorID: 'AD0032',
    firstAdvisorName: 'William Smith',
    firstAdvisorSplitPercentage: '20%',
  },
  {
    id: 3,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Advisor',
    splitPartnerID: '-',
    splitPartnerName: '-',
    splitPartnerPercentage: '-',
    firstAdvisorID: 'AD0032',
    firstAdvisorName: 'William Smith',
    firstAdvisorSplitPercentage: '20%',
  },
  {
    id: 4,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Advisor',
    splitPartnerID: '-',
    splitPartnerName: '-',
    splitPartnerPercentage: '-',
    firstAdvisorID: 'AD0032',
    firstAdvisorName: 'William Smith',
    firstAdvisorSplitPercentage: '20%',
  },
  {
    id: 5,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Advisor',
    splitPartnerID: '-',
    splitPartnerName: '-',
    splitPartnerPercentage: '-',
    firstAdvisorID: 'AD0032',
    firstAdvisorName: 'William Smith',
    firstAdvisorSplitPercentage: '20%',
  },

  {
    id: 6,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Split Partner',
    splitPartnerID: 'NI 0032',
    splitPartnerName: 'William Smith',
    splitPartnerPercentage: '20%',
    firstAdvisorID: '-',
    firstAdvisorName: '-',
    firstAdvisorSplitPercentage: '-',
  },
  {
    id: 7,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Split Partner',
    splitPartnerID: 'NI 0032',
    splitPartnerName: 'William Smith',
    splitPartnerPercentage: '20%',
    firstAdvisorID: '-',
    firstAdvisorName: '-',
    firstAdvisorSplitPercentage: '-',
  },
  {
    id: 8,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Split Partner',
    splitPartnerID: 'NI 0032',
    splitPartnerName: 'William Smith',
    splitPartnerPercentage: '20%',
    firstAdvisorID: '-',
    firstAdvisorName: '-',
    firstAdvisorSplitPercentage: '-',
  },
  {
    id: 9,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Split Partner',
    splitPartnerID: 'NI 0032',
    splitPartnerName: 'William Smith',
    splitPartnerPercentage: '20%',
    firstAdvisorID: '-',
    firstAdvisorName: '-',
    firstAdvisorSplitPercentage: '-',
  },
  {
    id: 10,
    clientID: '5648256326',
    clientName: 'John Whick',
    splitType: 'Split Partner',
    splitPartnerID: 'NI 0032',
    splitPartnerName: 'William Smith',
    splitPartnerPercentage: '20%',
    firstAdvisorID: '-',
    firstAdvisorName: '-',
    firstAdvisorSplitPercentage: '-',
  },
];

export const ADVISOR_COLUMNS = [
  { headerName: 'Client ID', field: 'clientID' },
  { headerName: 'Client Name', field: 'clientName' },
  { headerName: 'Split Type', field: 'splitType' },
  { headerName: 'Split Partner ID', field: 'splitPartnerID' },
  { headerName: 'Split Partner Name', field: 'splitPartnerName' },
  {
    headerName: 'Split Partner Percentage',
    field: 'splitPartnerPercentage',
  },
  { headerName: '1st Advisor ID', field: 'firstAdvisorID' },
  { headerName: '1st Advisor Name', field: 'firstAdvisorName' },
  {
    headerName: '1st Advisor Split Percentage',
    field: 'firstAdvisorSplitPercentage',
  },
];
