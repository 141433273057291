export const PAYOUT_DATA = [
  {
    id: 1,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 2,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 3,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 4,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 5,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 6,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 7,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
  {
    id: 8,
    employeeName: 'William Smith',
    period: 'Monthly',
    totalGrossFCI: '£15,000.00',
    totalAdvisorSplit: '£5000.00',
    totalDeduction: '£250.00',
    netPayout: '£5000.00',
    lgShareMargin: '£5000.00',
  },
];

export const PAYOUT_COLUMNS = [
  { headerName: 'Advisors Name', field: 'advisorName' },
  { headerName: 'Period', field: 'period' },
  {
    headerName: 'Total Gross FCI',
    field: 'totalGrossFCI',
    renderCell: row => `£${row?.totalGrossFCI?.toFixed(2)}`,
  },
  {
    headerName: 'Total Advisor Split',
    field: 'totalAdvisorSplit',
    renderCell: row => `£${row?.totalAdvisorSplit?.toFixed(2)}`,
  },
  {
    headerName: 'Total Deduction',
    field: 'totalDeduction',
    editAble: true,
    renderCell: row => `£${row?.totalDeduction?.toFixed(2)}`,
  },
  {
    headerName: 'Net Payout',
    field: 'netPayout',
    renderCell: row => `£${row?.netPayout?.toFixed(2)}`,
  },
  {
    headerName: 'LG Share Margin',
    field: 'lgShareMargin',
    renderCell: row => `£${row?.margin?.toFixed(2)}`,
  },
];
