import { call, put, takeEvery } from 'redux-saga/effects';
import { GET_REFUND_PAYMENTS } from '_redux/types/refundpayments.types';
import { getRefundPaymentsService } from '_redux/services/refundPayments.service';
import { refundPaymentActions } from '_redux/slices/refundPayment.slice';
import { GET_REFUND_PAYMENT_BY_ID } from '_redux/types/refundpayments.types';
import { getRefundPaymentByIdService } from '_redux/services/refundPayments.service';
import { REFUND_PAYMENT_ADMIN_VERIFICATION } from '_redux/types/refundpayments.types';
import { adminVerificationService } from '_redux/services/refundPayments.service';
import toast from 'react-hot-toast';

export function* getRefundPayments(action) {
  try {
    const response = yield call(getRefundPaymentsService, action.payload);

    if (response?.status === 200) {
      yield put(
        refundPaymentActions.actions.setRefundPayments({
          refundPaymentsRequests: response.data,
        })
      );
    }
  } catch (err) {
    // const errorMessage = err?.response?.data?.message;
    console.log('error: ', err);
  }
}

export function* getRefundPaymentById(action) {
  try {
    const response = yield call(getRefundPaymentByIdService, action.payload);

    if (response?.status === 200) {
      yield put(
        refundPaymentActions.actions.setSelectedRefundPayment({
          selectedRefundPayment: response.data,
        })
      );
    }
  } catch (err) {
    console.log('error: ', err);
  }
}

export function* adminVerification(action) {
  try {
    const response = yield call(adminVerificationService, action.payload);

    if (response?.status === 200) {
      toast.success('Status Updated');
    } else {
      toast.error('Updation Failed');
    }
  } catch (err) {
    console.log('error: ', err);
    toast.error('Updation Failed');
  }
}

export function* watchRefundPaymentsAsync() {
  yield takeEvery(GET_REFUND_PAYMENTS, getRefundPayments);
  yield takeEvery(GET_REFUND_PAYMENT_BY_ID, getRefundPaymentById);
  yield takeEvery(REFUND_PAYMENT_ADMIN_VERIFICATION, adminVerification);
}
