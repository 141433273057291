import React from 'react';
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import styles from './plans.module.scss';
import Row from './Row/Row';
import Head from './Head/Head';
import Animation from 'utils/Animation';

const Plans = ({ plans }) => {
  return (
    <Card
      className={styles.plansCard}
      sx={{ boxShadow: 'none', borderRadius: '0.6rem' }}
    >
      <CardHeader
        title={
          <Animation>
            <Typography variant="h5" fontWeight="bold" className={styles.title}>
              Top Plans
            </Typography>
          </Animation>
        }
      />
      <TableContainer
        sx={{
          maxHeight: '312px',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '3px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f0f0',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
        }}
      >
        <Table stickyHeader>
          <Head />
          <TableBody>
            {plans?.map((plan, index) => (
              <Row plan={plan} key={index} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default Plans;
