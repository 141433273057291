import React, { useEffect, useMemo, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { paymentColumns } from 'utils/requests';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import RefundPaymentRequest from 'components/RefundPaymentRequest/RefundPaymentRequest';
import { useDispatch, useSelector } from 'react-redux';
import { GET_REFUND_PAYMENTS } from '_redux/types/refundpayments.types';
import { refundPaymentActions } from '_redux/slices/refundPayment.slice';
import { GET_REFUND_PAYMENT_BY_ID } from '_redux/types/refundpayments.types';

const Tracking = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const refundPaymentsRequests = useSelector(
    refundPaymentActions.selectors.getRefundPayments
  );

  const handleOpen = row => {
    dispatch({ type: GET_REFUND_PAYMENT_BY_ID, payload: { id: row?.id } });

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch({ type: GET_REFUND_PAYMENTS, payload: { limit: 10, skip: 0 } });
  }, [dispatch]);

  const columns = useMemo(() => paymentColumns(handleOpen), [handleOpen]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <GenericTable
        title="Refund Request"
        data={refundPaymentsRequests}
        columns={columns}
        pageConfig={{
          page: 0,
          setPage: () => {},
          rowsPerPage: 10,
          setRowsPerPage: () => {},
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
      />
      <ModalContainer open={open}>
        <RefundPaymentRequest handleClose={handleClose} />
      </ModalContainer>
    </>
  );
};

export default Tracking;
