import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import CsvIcon from 'assets/icons/csv.svg'; // Use a generic icon for .csv or .xlsx
import styles from './uploadFile.module.scss';
import { Upload } from 'assets/svgs';
import { Title } from 'components/common/Title';
import { useDispatch, useSelector } from 'react-redux';
import { UPLOAD_FILE, SET_UPLOAD_PROGRESS } from '_redux/types/files.types';
import { filesActions } from '_redux/slices/files.slice';

const UploadFile = ({ onUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const uploadProgress = useSelector(filesActions.selectors.getUplaodProgress);
  const [category, setCategory] = useState('monthly');
  const dispatch = useDispatch();

  const handleFileChange = event => {
    setFile(event.target.files[0]);
    dispatch({ type: SET_UPLOAD_PROGRESS, payload: 0 });
  };

  const handleUpload = () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('category', category);

    setUploading(true);

    dispatch({
      type: UPLOAD_FILE,
      payload: {
        formData,
        onUploadComplete: () => {
          setUploading(false);
          onUploadComplete();
        },
      },
    });
  };

  const uploadedSize = Math.round((uploadProgress / 100) * file?.size);
  const remainingSize = file ? file?.size - uploadedSize : 0;

  return (
    <Box className={styles.uploadContainer}>
      <Box sx={{ width: '8rem' }}>
        <Title title={'Upload File'} />
      </Box>
      <br />
      <Typography variant="body2" className={styles.subtitle}>
        Upload and attach files to this project.
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={category === 'monthly'}
              onChange={() => setCategory('monthly')}
            />
          }
          label="Monthly Report"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={category === 'weekly'}
              onChange={() => setCategory('weekly')}
            />
          }
          label="Weekly Report"
        />
      </Box>
      <Box className={styles.fileUploadBox}>
        <input
          type="file"
          accept=".csv, .xlsx"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-upload"
        />
        <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
          <Upload />
          <Typography variant="body1" color="#000000">
            Choose a file or drag & drop it here
          </Typography>
          <Typography variant="body2" color="#687182">
            Maximum file size 50MB
          </Typography>
        </label>
      </Box>
      {file && (
        <Box className={styles.fileDetailsBox}>
          <Box className={styles.fileIcon}>
            <img src={CsvIcon} alt="CSV or XLSX Icon" />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1" className={styles.fileName}>
              {file.name}
            </Typography>
            <Typography variant="body2" className={styles.fileSize}>
              {`${Math.round(uploadedSize / 1024)} KB uploaded of ${Math.round(file.size / 1024)} KB`}{' '}
              • {`${Math.round(remainingSize / 1024)} KB remaining`}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              className={styles.progressBar}
            />
          </Box>
          <IconButton
            onClick={() => setFile(null)}
            className={styles.cancelButton}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )}
      <Button
        variant="contained"
        fullWidth
        startIcon={
          uploading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <CloudUploadIcon />
          )
        }
        onClick={handleUpload}
        disabled={!file || uploading}
        className={styles.uploadButton}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </Button>
    </Box>
  );
};

export default UploadFile;
