import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_COMMISSION_SPLITS } from '_redux/types/commissionSplit.types';
import { commissionSplitActions } from '_redux/slices/commissionSplit.slice';
import { fetchCommissionSplitsService } from '_redux/services/commissionSplit.service';
import { updateCommissionSplitService } from '_redux/services/commissionSplit.service';
import toast from 'react-hot-toast';
import { UPDATE_COMMISSION_SPLIT } from '_redux/types/commissionSplit.types';

export function* fetchCommissionSplitsSaga(action) {
  try {
    const response = yield call(fetchCommissionSplitsService, action.payload);
    if (response?.status == 200) {
      yield put(
        commissionSplitActions.actions.setCommissionSplits({
          commissions: response?.data,
          totalCommissions: response?.data?.length,
        })
      );
    }
  } catch (error) {
    console.log('Error fetching commission splits', error);
  }
}

function* updateCommissionSplitSaga(action) {
  try {
    const response = yield call(updateCommissionSplitService, action.payload);

    if (response.status === 200) {
      toast.success('Commission Split Updated');
      yield put({
        type: FETCH_COMMISSION_SPLITS,
        payload: {
          limit: 10,
          skip: 0,
        },
      });
    }
  } catch (error) {
    console.error('Error updating file:', error);
  }
}

export function* watchCommissionSplitsAsync() {
  yield takeEvery(FETCH_COMMISSION_SPLITS, fetchCommissionSplitsSaga);
  yield takeEvery(UPDATE_COMMISSION_SPLIT, updateCommissionSplitSaga);
}
