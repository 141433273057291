import React, { useEffect, useState } from 'react';
import { GenericTable } from 'components/common/GenericTable';
import { PAYOUT_COLUMNS } from 'utils/payout';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_PAYOUTS } from '_redux/types/payout.types';
import { payoutActions } from '_redux/slices/payout.slice';
import UserPayRequest from 'components/UserPayRequest/UserPayRequest';
import UserRefundRequest from 'components/UserRefundRequest/UserRefundRequest';
import { UPDATE_PAYOUT } from '_redux/types/payout.types';

const Payout = ({ handleTabChange, tab }) => {
  const dispatch = useDispatch();
  const payouts = useSelector(payoutActions.selectors.getPayouts);
  const totalPayouts = useSelector(payoutActions.selectors.getTotalPayouts);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isPayModalOpen, setPayModalOpen] = useState(false);
  const [isRefundModalOpen, setRefundModalOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: FETCH_PAYOUTS,
      payload: {
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        period: 'weekly',
      },
    });
  }, [dispatch, page, rowsPerPage]);

  const handleEarlyPaymentClick = () => {
    setPayModalOpen(true);
  };

  const handleRefundRequestClick = () => {
    setRefundModalOpen(true);
  };

  const handleModalClose = () => {
    setPayModalOpen(false);
    setRefundModalOpen(false);
  };

  const handleUpdateRow = async updatedData => {
    try {
      dispatch({
        type: UPDATE_PAYOUT,
        payload: updatedData,
      });
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  const payout = payouts?.map((payout, index) => ({
    ...payout,
    id: index + 1,
  }));

  return (
    <>
      <GenericTable
        title="Advisors’s Payout"
        data={payout || []}
        columns={PAYOUT_COLUMNS}
        pageConfig={{
          page,
          setPage,
          rowsPerPage,
          setRowsPerPage,
          totalRows: totalPayouts,
        }}
        handleTabChange={handleTabChange}
        tab={tab}
        isTabs={false}
        isEdit={true}
        isRefundRequest={true}
        isPayRequest={true}
        onEarlyPayRequest={handleEarlyPaymentClick}
        onRefundRequest={handleRefundRequestClick}
        onUpdateRows={handleUpdateRow}
      />
      {isPayModalOpen && (
        <UserPayRequest onClose={handleModalClose} open={isPayModalOpen} />
      )}
      {isRefundModalOpen && (
        <UserRefundRequest
          onClose={handleModalClose}
          open={isRefundModalOpen}
        />
      )}
    </>
  );
};

export default Payout;
