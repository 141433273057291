import React, { useEffect, useState } from 'react';
// import { GenericTable } from 'components/common/GenericTable';
import { SALES_COLUMNS, SALES_DATA } from 'utils/salesData';
import { useDispatch, useSelector } from 'react-redux';
import { errorLogsActions } from '_redux/slices/errorLogs.slice';
import { FETCH_ERROR_LOGS } from '_redux/types/errorLogs.types';
import { filesActions } from '_redux/slices/files.slice';
import { FETCH_FILE_DATA } from '_redux/types/files.types';
import { TextField } from '@mui/material';
import ValidationTable from 'components/common/GenericTable/ValidationTable';
import { UPDATE_VALIDATION } from '_redux/types/errorLogs.types';

const Validation = ({ handleTabChange, tab, tabs }) => {
  const dispatch = useDispatch();
  const errorLogs = useSelector(errorLogsActions.selectors.getErrorLogs);
  const fileData = useSelector(filesActions.selectors.getFileData);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [updatedRows, setUpdatedRows] = useState({});

  const handleFieldChange = (rowId, field, value) => {
    setUpdatedRows(prev => ({
      ...prev,
      [rowId]: {
        ...prev[rowId],
        [field]: value,
      },
    }));
  };

  useEffect(() => {
    dispatch({
      type: FETCH_ERROR_LOGS,
      payload: { limit: rowsPerPage, skip: page * rowsPerPage },
    });
  }, [dispatch, page, rowsPerPage]);

  const columnsWithValidation = SALES_COLUMNS.map(col => ({
    ...col,
    renderCell: row => {
      const error = errorLogs.find(
        err =>
          err.transactionID === row.transactionID &&
          err.validationKey === col.field
      );
      return (
        <TextField
          fullWidth
          variant="standard"
          value={updatedRows[row.id]?.[col.field] || row[col.field] || ''}
          error={Boolean(error)}
          helperText={error ? error.errorDescription : ''}
          InputProps={{
            disableUnderline: true,
            sx: {
              padding: '5px 0',
              border: 'none',
              fontSize: 'inherit',
              color: 'inherit',
              backgroundColor: error ? '#fdecea' : 'inherit',
              borderColor: error ? '#f44336' : 'inherit',
            },
          }}
          onChange={e => handleFieldChange(row.id, col.field, e.target.value)}
        />
      );
    },
  }));

  useEffect(() => {
    if (errorLogs.length) {
      dispatch({ type: FETCH_FILE_DATA, payload: errorLogs?.[0]?.saleDataId });
    }
  }, [errorLogs.length, dispatch]);

  const handleUpdateRow = () => {
    const rowsToUpdate = Object.keys(updatedRows).map(rowId => ({
      id: rowId,
      updatedFields: updatedRows[rowId],
    }));
    try {
      dispatch({
        type: UPDATE_VALIDATION,
        payload: rowsToUpdate,
        id: errorLogs?.[0]?.saleDataId,
      });
      setUpdatedRows({});
    } catch (error) {
      console.error('Error updating data', error);
    }
  };

  return (
    <ValidationTable
      title="Validation Table"
      data={fileData?.SalesData || []}
      columns={columnsWithValidation}
      pageConfig={{
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows: SALES_DATA.length,
      }}
      handleTabChange={handleTabChange}
      tab={tab}
      isTabs={true}
      tabs={tabs}
      isEdit={Object.keys(updatedRows || {})?.length > 0}
      onUpdateRows={handleUpdateRow}
    />
  );
};

export default Validation;
